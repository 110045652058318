// <---------------- Miscellaneous ---------------->
export enum ClaimType {
  Home = "Home",
  Motor = "Motor",
}

export enum Unicode {
  NonBreakingSpace = "\u00a0",
}

// <---------------- Page Titles ---------------->

export enum PageTitle {
  BuildingAndContents = "Building and contents",
  CarInsurance = "Car insurance",
  SelectClaim = "Select claim",
}

export enum PageSubTitle {
  CarInsurance = "What are you claiming for",
}

// <---------------- Page Headings ---------------->
export enum HomeClaimHeading {
  BuildingAndContents = "Home insurance claim",
}

export enum MotorClaimHeading {
  CarInsurance = "Car insurance claim",
}

// <---------------- Page Subheadings ---------------->

export enum HomeClaimSubheading {
  BuildingAndContents = "What are you claiming for?",
}

export enum MotorClaimSubheading {
  CarInsurance = "What are you claiming for?",
}

// <------------------ Storage Keys ------------------>
export const STORAGE_KEY_POLICY_NUMBER = "policyNumber";
export const STORAGE_KEY_IS_LANDLORD_POLICY = "isLandlordPolicy";
