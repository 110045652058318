import { lazy } from "react";
import { ClaimType, HomeClaimHeading, MotorClaimHeading, PageSubTitle, PageTitle } from "../constants";

const BuildingAndContentsClaim = lazy(() => import("../../views/BuildingAndContentsClaim"));
const CarInsuranceClaim = lazy(() => import("../../views/CarInsuranceClaim"));

export enum HomeClaimRoute {
  BuildingAndContents = "building-and-contents",
}

export enum MotorClaimRoute {
  CarInsurance = "what-are-you-claiming-for",
}

export enum HomeClaimRouteName {
  BuildingAndContents = "Building and contents claim",
}

export enum MotorClaimRouteName {
  CarInsurance = "Car insurance claim",
}

export const getHomeClaimUrl = (route: HomeClaimRoute) =>
  `${process.env.PUBLIC_URL}/${ClaimType.Home.toLowerCase()}/${route}`;

export const getMotorClaimUrl = (route: MotorClaimRoute) =>
  `${process.env.PUBLIC_URL}/${ClaimType.Motor.toLowerCase()}/${route}`;

export interface RouteInformation {
  key: HomeClaimRoute | MotorClaimRoute;
  path: string;
  name: HomeClaimRouteName | MotorClaimRouteName;
  heading: HomeClaimHeading | MotorClaimHeading;
  subheading?: PageSubTitle;
  currentPage: PageTitle;
  claimType?: ClaimType;
  element: JSX.Element;
}

export const homeClaimRoutes: RouteInformation[] = [
  {
    key: HomeClaimRoute.BuildingAndContents,
    path: getHomeClaimUrl(HomeClaimRoute.BuildingAndContents),
    name: HomeClaimRouteName.BuildingAndContents,
    heading: HomeClaimHeading.BuildingAndContents,
    currentPage: PageTitle.BuildingAndContents,
    claimType: ClaimType.Home,
    element: <BuildingAndContentsClaim />,
  },
];

export const motorClaimRoutes: RouteInformation[] = [
  {
    key: MotorClaimRoute.CarInsurance,
    path: getMotorClaimUrl(MotorClaimRoute.CarInsurance),
    name: MotorClaimRouteName.CarInsurance,
    heading: MotorClaimHeading.CarInsurance,
    subheading: PageSubTitle.CarInsurance,
    currentPage: PageTitle.CarInsurance,
    claimType: ClaimType.Motor,
    element: <CarInsuranceClaim />,
  },
];

export const allRoutes = [...homeClaimRoutes, ...motorClaimRoutes];
