import { RacwaThemeProvider } from "@racwa/react-components";
import { Backdrop, ScrollToTop } from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { TrackPageChanges } from "./shared/components/TrackPageChanges";
import Views from "./views";

export const App = () => (
  <RacwaThemeProvider>
    <RecoilRoot>
      <Backdrop />
      <BrowserRouter>
        <ScrollToTop />
        <Views />
        <TrackPageChanges />
      </BrowserRouter>
    </RecoilRoot>
  </RacwaThemeProvider>
);

export default App;
