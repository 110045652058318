import { LinkProps } from "@mui/material";
import {
  FooterProps,
  NavBreadcrumbsProps,
  RacwaBreadcrumbDetails,
  RacwaStandardPageTemplateProps,
  theme,
} from "@racwa/react-components";
import { EMPTY_URL } from "raci-react-library";
import { useLocation } from "react-router-dom";
import { ClaimType, PageTitle } from "../../constants";
import { RouteInformation, allRoutes } from "../../routing/routes.config";

const getLinkProps = (link?: string): Partial<LinkProps> => ({
  href: link ?? EMPTY_URL,
});

export const getBreadcrumbDetails = (currentRoute?: RouteInformation): RacwaBreadcrumbDetails => {
  switch (currentRoute?.claimType) {
    case ClaimType.Home:
      return {
        links: [
          {
            name: "Make a claim",
            href: process.env.REACT_APP_RAC_MAKE_A_CLAIM_BUILDING_AND_CONTENTS_PAGE ?? EMPTY_URL,
          },
        ],
        currentPage: { name: currentRoute.currentPage },
      };
    case ClaimType.Motor:
      return {
        links: [{ name: "Make a claim", href: process.env.REACT_APP_RAC_MAKE_A_CLAIM_CAR_PAGE ?? EMPTY_URL }],
        currentPage: { name: currentRoute.currentPage },
      };
    default:
      return {
        links: [{ name: "Make a claim", href: process.env.REACT_APP_RAC_MAKE_A_CLAIM_PAGE ?? EMPTY_URL }],
        currentPage: { name: PageTitle.SelectClaim },
      };
  }
};

export const useStandardPage = (): RacwaStandardPageTemplateProps => {
  const location = useLocation();

  const currentRoute = allRoutes.find((route) => route.path.toLowerCase() === location.pathname.toLowerCase());

  const heading = currentRoute?.heading ?? "";

  const breadcrumbs = getBreadcrumbDetails(currentRoute);

  const navBreadcrumbProps: NavBreadcrumbsProps = {
    homeLink: process.env.REACT_APP_RAC_HOME_PAGE ?? EMPTY_URL,
  };

  const footerProps: FooterProps = {
    variant: "sidebar",
    privacyLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_PRIVACY_PAGE),
    securityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_SECURITY_PAGE),
    disclaimerLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_DISCLAIMER_PAGE),
    accessibilityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_ACCESSIBILITY_PAGE),
  };

  let notificationBannerContent;
  let headerEndAction = <></>;

  /*
   ** WARNING:
   ** KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO TO THE BELOW IF STATEMENT
   ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET ENVIRONMENT
   ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE AND SHIP CODE
   ** (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED JAVASCRIPT ARTIFACTS)
   */
  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT" ||
    process.env.REACT_APP_ENVIRONMENT === "UAT"
  ) {
    const { HeaderButtons, TestBanner } = require("raci-react-library-npe");

    notificationBannerContent = <TestBanner />;

    headerEndAction = (
      <HeaderButtons
        appConfig={{
          showShieldEnvironmentSelector: false,
          showFeatureToggleSelector: false,
        }}
        npeButtons={[]}
        isUat={process.env.REACT_APP_ENVIRONMENT === "UAT"}
      />
    );
  }

  return {
    heading,
    bannerTextColour: theme.palette.primary.main,
    breadcrumbs,
    navBreadcrumbProps,
    footerProps,
    notificationBannerContent,
    showNotificationBanner: !!notificationBannerContent,
    headerEndAction,
  };
};

export default useStandardPage;
