import { OpenBackdrop } from "raci-react-library";
import React, { Suspense, useEffect } from "react";
import { Outlet, Route, Routes, useSearchParams } from "react-router-dom";
import { STORAGE_KEY_IS_LANDLORD_POLICY } from "../shared/constants";
import useStandardPage from "../shared/hooks/useStandardPage";
import { TitleGuard } from "../shared/routing/TitleGuard";
import { allRoutes } from "../shared/routing/routes.config";

const RacwaStandardPageTemplate = React.lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStandardPageTemplate })),
);

const NavigateExternal = ({ to }: { to: string }) => {
  useEffect(() => {
    window.location.replace(to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export const Views = () => {
  const [params] = useSearchParams();
  const standardPageProps = useStandardPage();

  const policyNumber = params.get("policyNumber");
  if (policyNumber) {
    sessionStorage.setItem("policyNumber", policyNumber);
  }

  const isLandlordPolicy = params.get("isLandlordPolicy");
  if (isLandlordPolicy) {
    sessionStorage.setItem(STORAGE_KEY_IS_LANDLORD_POLICY, isLandlordPolicy);
  }

  const defaultRoute = process.env.REACT_APP_RAC_MAKE_A_CLAIM_PAGE ?? "";

  return (
    <>
      <TitleGuard />
      <Routes>
        <Route path={process.env.PUBLIC_URL} element={<NavigateExternal to={defaultRoute} />} />
        <Route
          path={process.env.PUBLIC_URL}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStandardPageTemplate {...standardPageProps}>
                <Outlet />
              </RacwaStandardPageTemplate>
            </Suspense>
          }
        >
          {allRoutes.map(({ key, path, element }) => (
            <Route key={key} path={path} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}
        </Route>
        <Route path="*" element={<NavigateExternal to={defaultRoute} />} />
      </Routes>
    </>
  );
};

export default Views;
